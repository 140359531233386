<template>
  <div>
    <div class="pt-3 d-flex justify-space-between align-end" v-if="data">
      <div>
        <div class="pb-3">{{ Moment(data.created_at).format('YYYY-MM-DD HH:mm') }} -- <span :class="statusType">{{
          $t(`order.status.${data.status}`)
        }}</span></div>
        <div class="pb-3">訂單編號: {{ data.order_no }}</div>
        <div class="pb-3">付款方式: {{ $t(`data.payment_type.${data.payment_type}`) }}</div>
      </div>
      <div class="pb-5">
        <v-btn v-if="currMemberRole._isManager" depressed dark color="primary"
          :to="{ name: 'store-cooperation-info', params: { cooperateProviderId: providerId } }">
          供應商資訊
        </v-btn>
      </div>
    </div>
    <div class="d-flex justify-end align-end" v-if="false && data && data.adjustmentData && data.adjustmentData.length > 0">
      <v-btn depressed dark color="primary" @click="openOrderAdjustmentRecordDialog = true">
        查看訂單調整
      </v-btn>
    </div>
    <order-adjustment-record
      :openOrderAdjustmentRecordDialog="openOrderAdjustmentRecordDialog"
      :orderData="data || {}"
      :adjustmentData="data && data.adjustmentData || []"
      :providerId="providerId"
      :storeId="storeId"
      @updateOrderAdjustmentRecordDialogSwitch="(isOpen) => openOrderAdjustmentRecordDialog = isOpen"
    />
  </div>
</template>

<script lang="babel" type="text/babel">
import Moment from 'moment'

import ItemMixin from '@/components/form/custom/ItemMixin.js'
import orderConstants from "@/modules/base/config/orderConstants"
import OrderAdjustmentRecord from "@/modules/store/views/orderDetail/OrderAdjustmentRecord";

import { mapGetters } from 'vuex'

export default {
    mixins: [ItemMixin],
    components: {
      OrderAdjustmentRecord
    },
    data: () => ({
        data: null,
        orderConstants: orderConstants,
        Moment: Moment,
        openOrderAdjustmentRecordDialog: false
    }),
    computed: {
      ...mapGetters('member', ['currMemberRole']),
      providerId() {
        return this.$route.params.providerId
      },
      storeId() {
        return this.$store.getters[`member/storeId`];
      },
      storeInfo() {
        if (!this.data) return []
        if (!this.data.store_info) return []
        const store_info = this.data.store_info
        return [
          {text: '客戶', value: this.data.store_name},
          {text: '聯絡人', value: store_info.contact},
          {text: '電話', value: store_info.phone},
          {text: '地址', value: `${store_info.city}${store_info.district}${store_info.address}`},
          {text: '統一編號', value: store_info.uniform_number},
        ]
      },
      statusType() {
        if (!this.data) return ''
        switch (this.data.status) {
          case "finished":
            return "lighten-text";
          case "reject":
          case "canceled":
            return "error-text";
          case "request_return":
              return "dark-text";
          default:
            return "secondary-text";
        }
      },

    },
    async created() {

    },
    watch: {
        propsValue: {
            immediate: true,
            deep: true,
            handler() {
                if (!this.propsValue) return
                this.data = this.$eagleLodash.cloneDeep(this.propsValue)
            },
        },
        data: {
            deep: true,
            handler() {

            }
        },

    },
    
    methods: {},
}
</script>
<style scoped lang="sass">
.lighten-text
  color: #37D21E

.error-text
  color: #F61212

.dark-text
  color: #000000

.secondary-text
  color: #009775
</style>

